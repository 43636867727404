<template>
  <div>
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <div v-else class="flex flex-col gap-4 w-full">
      <c-card class="w-full flex flex-col p-5">
        <div class="w-full gap-5 mb-6 flex">
          <c-card
            class="w-full grid grid-cols-3 divide-x divide-romanSilver py-6 px-4"
          >
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet"
                >Total Leave Entitlements (Days)</span
              >
              <span class="text-lg font-extrabold text-carrotOrange">{{
                summary.totalmaximumApplicableDays
              }}</span>
            </div>
            <div class="flex flex-col pl-4">
              <span class="text-xs font-semibold uppercase text-jet"
                >Total Used Leave (Days)</span
              >
              <span class="text-lg font-extrabold text-mediumSeaGreen">{{
                summary.daysUsed
              }}</span>
            </div>
            <div class="flex flex-col pl-4">
              <span class="text-xs font-semibold uppercase text-jet"
                >Total Unused Leave (Days)</span
              >
              <span class="text-lg font-extrabold text-desire">{{
                summary.leaveBalance
              }}</span>
            </div>
          </c-card>
        </div>
        <template v-slot:footer>
          <CardFooter
            printcard
            reloadcard
            :show-search="false"
            @reload="getYearEndBalance()"
            @actionModal="onFilter()"
            @sortType="onQueryFn({ sort: $event }, null, getYearEndBalance)"
            @searchResult="
              onQueryFn({ search: $event }, null, getYearEndBalance)
            "
          />
        </template>
      </c-card>

      <c-table
        :headers="headers"
        :items="balances"
        aria-label="year end leave table"
        :loading="isFetching"
        :has-number="false"
        v-if="balances.length || isFetching"
      >
        <template v-slot:item="{ item }">
          <span v-if="item.year" class="text-darkPurple text-sm font-normal">
            {{ item.data.year }}
          </span>
          <span
            v-if="item.totalMaximumApplicableDays"
            class="text-darkPurple text-sm font-normal"
          >
            {{ item.data.totalMaximumApplicableDays }}
          </span>
          <span
            v-if="item.leaveBalance"
            class="text-darkPurple text-sm font-normal"
          >
            {{ item.data.leaveBalance }}
          </span>
          <span
            v-if="item.carriedForwardLimit"
            class="text-darkPurple text-sm font-normal"
          >
            {{ item.data.carriedForwardLimit }}
          </span>
          <div v-if="item.daysUsed" class="cursor-pointer">
            <div @click="openSummary($AuthUser, item.data.year)">
              <c-icon icon-name="icon-eye" size="xs" />
            </div>
          </div>
        </template>
      </c-table>

      <div v-else class="flex flex-col justify-center items-center gap-0 px-10">
        <c-icon
          icon-name="paygrade_Illustration"
          size=""
          class-name="w-56 h-56"
        />
        <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
          Year end leave balances can be found here. This is empty now, but you
          can start addingleave balances to populate this area.
        </p>
      </div>

      <FilterTable
        v-if="isOpenFilter"
        :filter-data="filterOptions"
        @close="isOpenFilter = false"
        @submit="isOpenFilter = false"
      />

      <ViewDaysBackRequest ref="viewDaysBackRequestRef" />
      <AddLeaveRequest ref="leaveRequestRef" />
      <ViewLeaveRequest ref="viewRequestRef" />
      <GetLeaveBackRequest ref="getLeaveBackRef" />
    </div>

    <RightSideBar
      v-if="isOpen"
      @close="isOpen = false"
      submit="View Leave History"
      button-class="bg-dynamicBackBtn text-white"
      @submit="$router.push({name: 'EssLeaveHistory', params: {id:  selectedEmployee.id}})"
      width="width:520px"
      submitButtonWidth="11.875rem"
      :loading="loading"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center gap-0">
          <back-button
            @onClick="isOpen = false"
            variant="secondary"
            style="padding: 0"
          />
          <span class="text-darkPurple font-bold text-lg">Leave Summary</span>
        </div>
      </template>
      <div class="w-full flex my-3">
        <CCard class="w-full flex flex-col gap-3 py-2 px-3">
          <div class="flex w-full">
            <p
              class="flex flex-grow uppercase text-romanSilver text-xs font-semibold"
            >
              Employee Name
            </p>
            <p class="flex text-sm font-semibold">
              {{ selectedEmployee.fname }} {{ selectedEmployee.lname }}
            </p>
          </div>
          <div class="flex w-full">
            <p
              class="flex flex-grow uppercase text-romanSilver text-xs font-semibold"
            >
              Job Title
            </p>
            <p class="flex text-sm font-semibold">
              {{
                selectedEmployee.orgFunction
                  ? selectedEmployee.orgFunction.name
                  : "---"
              }}
            </p>
          </div>
          <div class="flex w-full">
            <p
              class="flex flex-grow uppercase text-romanSilver text-xs font-semibold"
            >
              Level
            </p>
            <p class="flex text-sm font-semibold">
              {{
                selectedEmployee.userLevel
                  ? selectedEmployee.userLevel.name
                  : "---"
              }}
            </p>
          </div>
          <div class="flex w-full">
            <p
              class="flex flex-grow uppercase text-romanSilver text-xs font-semibold"
            >
              Function
            </p>
            <p class="flex text-sm font-semibold">
              {{
                selectedEmployee.userDesignation
                  ? selectedEmployee.userDesignation.name
                  : "---"
              }}
            </p>
          </div>
          <div class="w-full h-[1px] border mt-4" />

          <c-table
            :headers="summaryHeaders"
            :items="leaveCategories"
            :has-number="false"
            aria-label="year end leave balance table"
            v-if="leaveCategories.length"
            class="-mt-2"
          >
            <template v-slot:item="{ item }">
              <div
                v-if="item.name"
                class="flex justify-start items-start gap-2"
              >
              {{ item.data.name }}
            </div>
              <div
                v-if="item.daysUsed"
                class="flex justify-start items-start gap-2"
              >
              {{ item.data.daysUsed }}
            </div>
              <div
                v-if="item.balance"
                class="flex justify-start items-start gap-2"
              >
              {{ item.data.balance }}
            </div>
            </template>
          </c-table>
        </CCard>
      </div>
    </RightSideBar>

  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import CTable from "@scelloo/cloudenly-ui/src/components/table";
import CCard from "@scelloo/cloudenly-ui/src/components/card";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import RightSideBar from "@/components/RightSideBar";
import CIcon from "@/components/Icon";

export default {
  components: {
    CCard,
    CTable,
    CardFooter,
    FilterTable,
    CIcon,
    RightSideBar,
    ViewDaysBackRequest: () => import("./sideActions/ViewDaysBackRequest"),
    AddLeaveRequest: () => import("./sideActions/AddLeaveRequest"),
    ViewLeaveRequest: () => import("./sideActions/ViewLeaveRequest"),
    GetLeaveBackRequest: () => import("./sideActions/GetLeaveBack"),
  },
  data() {
    return {
      isOpenFilter: false,
      isOpen: false,
      isFetching: false,
      isLoading: true,
      filterOptions: [],
      setQueryTimeout: null,
      queryParams: {},
      summary: {},
      balances: [],
      loading: false,
      selectedEmployee: null,
      headers: [
        { title: "Leave Year", value: "year" },
        {
          title: "Total Leave Entitlement (Days)",
          value: "totalMaximumApplicableDays",
        },
        { title: "Leave Balance (Days)", value: "leaveBalance" },
        { title: "Carry Forward Limit", value: "carriedForwardLimit" },
        { title: "", value: "daysUsed", image: true },
      ],
      summaryHeaders: [
        { title: "Leave Type", value: "name" },
        { title: "Total days taken(To Date)", value: "daysUsed" },
        { title: "Leave Balance", value: "balance" },
      ],
      leaveCategories: [],
    };
  },
  methods: {
    openSummary(user, year) {
      this.isOpen = true;
      this.loading = true;
      this.selectedEmployee = user;
      this.$_yearEndBalanceUserSummary(
        user.id,
        year
      ).then((response) => {
        this.leaveCategories = response.data.leaveCategories;
        this.loading = false;
      });
    },
    onQueryFn(queryParams, payload, callback) {
      clearTimeout(this.setQueryTimeout);
      this.setQueryTimeout = setTimeout(() => {
        if (queryParams === undefined) {
          this.queryParams = {};
          return callback({ ...this.queryParams, ...payload });
        }
        this.queryParams = { ...this.queryParams, ...queryParams };
        return callback({ ...this.queryParams, ...payload });
      }, 1000);
    },
    onFilter() {
      this.filterOptions = [
        { header: "Location(s)", optionsBox: this.locationOptions },
        { header: "Function(s)", optionsBox: this.functionOptions },
        { header: "Level(s)", optionsBox: this.levelOptions },
        { header: "Designation(s)", optionsBox: this.designationOptions },
      ];
      this.isOpenFilter = true;
    },

    async getYearEndBalance(params) {
      this.isFetching = true;
      const payload = { userId: this.$AuthUser.id, params };
      await this.$_yearEndLeaveBalance(payload)
        .then(({ data }) => {
          this.summary = data.summary;
          this.balances = data.data;
          this.isFetching = false;
        })
        .catch(() => {
          this.$toasted.error(
            "Error fetching data, please check your network.",
            { duration: 3000 }
          );
        });
    },
    async bootstraoModule() {
      this.isLoading = true;
      await this.getYearEndBalance();
      this.isLoading = false;
    },
  },
  created() {
    this.bootstraoModule();
  },
};
</script>

<style>
.userContainer {
  padding: 8px;
  border-bottom: 1px dashed #878e99;
}

input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}

input[type="radio"]:checked {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  background-color: #f15a29 !important;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}
</style>
